/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SourceSchema = {
    type: SourceSchema.type;
    value?: string;
    sourceId?: string;
};
export namespace SourceSchema {
    export enum type {
        YAML = 'yaml',
        EXCEL = 'excel',
        API = 'api',
    }
}

