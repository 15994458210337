/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ModelStrAttrOp } from './ModelStrAttrOp';
export type ModelStrAttrCriterion = {
    attributeId: number;
    operator: ModelStrAttrOp;
    value: string;
    dataType: ModelStrAttrCriterion.data_type;
};
export namespace ModelStrAttrCriterion {
    export enum data_type {
        STR = 'str',
    }
}

