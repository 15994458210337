/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ThinNumericInputAttribute = {
    group: string;
    displayName?: string;
    caption?: string;
    mandatory: boolean;
    important: boolean;
    purpose?: Array<string>;
    sequence: number;
    similarityWeight?: number;
    /**
     * Разрешить аналогичность по пересечению значений multi-value атрибутов при подборе аналогов
     */
    similarityIntersection?: boolean;
    name: string;
    valueOptions?: Array<string>;
    multiValue?: boolean;
    minValue?: (number | null);
    maxValue?: (number | null);
    similarityMargin?: number;
    unitId?: (number | null);
    precision?: number;
    dataType: ThinNumericInputAttribute.data_type;
};
export namespace ThinNumericInputAttribute {
    export enum data_type {
        NUMERIC = 'NUMERIC',
    }
}

