/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EnumFilterOption } from './EnumFilterOption';
export type StringEnumFilter = {
    /**
     * Идентификатор фильтра
     */
    id: string;
    /**
     * Название фильтра
     */
    name: string;
    /**
     * Описание фильтра (HTML)
     */
    description?: (string | null);
    /**
     * Назначение фильтра
     */
    purpose: Array<string>;
    /**
     * Тип фильтра
     */
    type?: StringEnumFilter.type;
    /**
     * Список возможных значение фильтра
     */
    options: Array<EnumFilterOption>;
    /**
     * Тип данных
     */
    dataType: StringEnumFilter.data_type;
};
export namespace StringEnumFilter {
    /**
     * Тип фильтра
     */
    export enum type {
        ENUM = 'ENUM',
    }
    /**
     * Тип данных
     */
    export enum data_type {
        STRING = 'STRING',
    }
}

