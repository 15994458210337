/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ThinBooleanInputAttribute = {
    group: string;
    displayName?: string;
    caption?: string;
    mandatory: boolean;
    important: boolean;
    purpose?: Array<string>;
    sequence: number;
    similarityWeight?: number;
    /**
     * Разрешить аналогичность по пересечению значений multi-value атрибутов при подборе аналогов
     */
    similarityIntersection?: boolean;
    name: string;
    dataType: ThinBooleanInputAttribute.data_type;
};
export namespace ThinBooleanInputAttribute {
    export enum data_type {
        BOOLEAN = 'BOOLEAN',
    }
}

