/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ListStrAttrOp } from './ListStrAttrOp';
export type ListStrAttrCriterion = {
    attributeId: number;
    operator: ListStrAttrOp;
    value: Array<string>;
    dataType: ListStrAttrCriterion.data_type;
};
export namespace ListStrAttrCriterion {
    export enum data_type {
        LIST_STR = 'list_str',
    }
}

