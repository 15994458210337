/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type NumericSpecificationShort = {
    /**
     * Идентификатор характеристики
     */
    id: string;
    /**
     * Название характеристики
     */
    name: string;
    /**
     * Группа характеристик
     */
    group: string;
    /**
     * Значение
     */
    value: string;
    /**
     * Значения
     */
    values: Array<string>;
    /**
     * Назначение
     */
    purpose: Array<string>;
    /**
     * Порядок сортировки
     */
    sequence: number;
    /**
     * Тип данных
     */
    dataType: NumericSpecificationShort.data_type;
    /**
     * Точность числа
     */
    precision: number;
    /**
     * Единицы измерения
     */
    unit: string;
};
export namespace NumericSpecificationShort {
    /**
     * Тип данных
     */
    export enum data_type {
        NUMERIC = 'NUMERIC',
    }
}

