/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ModelListStrAttrOp } from './ModelListStrAttrOp';
export type ModelListStrAttrCriterion = {
    attributeId: number;
    operator: ModelListStrAttrOp;
    value: Array<string>;
    dataType: ModelListStrAttrCriterion.data_type;
};
export namespace ModelListStrAttrCriterion {
    export enum data_type {
        LIST_STR = 'list_str',
    }
}

