/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type BooleanSpecificationShort = {
    /**
     * Идентификатор характеристики
     */
    id: string;
    /**
     * Название характеристики
     */
    name: string;
    /**
     * Группа характеристик
     */
    group: string;
    /**
     * Значение
     */
    value: string;
    /**
     * Значения
     */
    values: Array<string>;
    /**
     * Назначение
     */
    purpose: Array<string>;
    /**
     * Порядок сортировки
     */
    sequence: number;
    /**
     * Тип данных
     */
    dataType: BooleanSpecificationShort.data_type;
};
export namespace BooleanSpecificationShort {
    /**
     * Тип данных
     */
    export enum data_type {
        BOOLEAN = 'BOOLEAN',
    }
}

