/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ShipmentChangeStatusSchema = {
    status: ShipmentChangeStatusSchema.status;
};
export namespace ShipmentChangeStatusSchema {
    export enum status {
        RC_ACCEPTED = 'rc_accepted',
        RC_PACKED = 'rc_packed',
        RC_ERP_SEND = 'rc_erp_send',
        RC_TO_DELIVERY = 'rc_to_delivery',
        WAREHOUSE_DELIVERED = 'warehouse_delivered',
        DELIVER_TO_CUSTOMER = 'deliver_to_customer',
        DECLINE = 'decline',
        TO_RC_READY = 'to_rc_ready',
        TO_RC_DELIVERED = 'to_rc_delivered',
    }
}

