/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CharacteristicActionReplace = {
    attributeId: number;
    action: CharacteristicActionReplace.action;
    fromValue: string;
    toValue: string;
};
export namespace CharacteristicActionReplace {
    export enum action {
        REPLACE = 'replace',
    }
}

