/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TnvedRuleNumAttrOp } from './TnvedRuleNumAttrOp';
export type TnvedRuleNumAttrCriterion = {
    attributeId: number;
    operator: TnvedRuleNumAttrOp;
    value: number;
    dataType: TnvedRuleNumAttrCriterion.data_type;
};
export namespace TnvedRuleNumAttrCriterion {
    export enum data_type {
        NUM = 'num',
    }
}

