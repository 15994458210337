/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TnvedRuleBoolAttrOp } from './TnvedRuleBoolAttrOp';
export type TnvedRuleBoolAttrCriterion = {
    attributeId: number;
    operator: TnvedRuleBoolAttrOp;
    value: boolean;
    dataType: TnvedRuleBoolAttrCriterion.data_type;
};
export namespace TnvedRuleBoolAttrCriterion {
    export enum data_type {
        BOOL = 'bool',
    }
}

