/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TnvedRuleStrAttrOp } from './TnvedRuleStrAttrOp';
export type TnvedRuleStrAttrCriterion = {
    attributeId: number;
    operator: TnvedRuleStrAttrOp;
    value: string;
    dataType: TnvedRuleStrAttrCriterion.data_type;
};
export namespace TnvedRuleStrAttrCriterion {
    export enum data_type {
        STR = 'str',
    }
}

