/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ActualizationError = {
    type?: ActualizationError.type;
    code?: string;
    text?: string;
};
export namespace ActualizationError {
    export enum type {
        RETRY = 'RETRY',
        ACTION = 'ACTION',
        TECHNICAL = 'TECHNICAL',
    }
}

