/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BoolAttrOp } from './BoolAttrOp';
export type BoolAttrCriterion = {
    attributeId: number;
    operator: BoolAttrOp;
    value: boolean;
    dataType: BoolAttrCriterion.data_type;
};
export namespace BoolAttrCriterion {
    export enum data_type {
        BOOL = 'bool',
    }
}

