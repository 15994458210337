/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SupplierInfoForUsedeskSchema = {
    companyName?: string;
    fullName?: string;
    phone: string;
    additionalPhone?: string;
    role: SupplierInfoForUsedeskSchema.role;
    link: string;
};
export namespace SupplierInfoForUsedeskSchema {
    export enum role {
        SUPPLIER = 'supplier',
    }
}

