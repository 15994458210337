/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StrAttrOp } from './StrAttrOp';
export type StrAttrCriterion = {
    attributeId: number;
    operator: StrAttrOp;
    value: string;
    dataType: StrAttrCriterion.data_type;
};
export namespace StrAttrCriterion {
    export enum data_type {
        STR = 'str',
    }
}

