/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CharacteristicActionListRemove = {
    attributeId: number;
    action: CharacteristicActionListRemove.action;
    value: string;
};
export namespace CharacteristicActionListRemove {
    export enum action {
        LIST_REMOVE = 'list_remove',
    }
}

