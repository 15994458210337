/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CharacteristicActionListAppend = {
    attributeId: number;
    action: CharacteristicActionListAppend.action;
    value: string;
};
export namespace CharacteristicActionListAppend {
    export enum action {
        LIST_APPEND = 'list_append',
    }
}

