/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type BoolFilter = {
    /**
     * Идентификатор фильтра
     */
    id: string;
    /**
     * Название фильтра
     */
    name: string;
    /**
     * Описание фильтра (HTML)
     */
    description?: (string | null);
    /**
     * Назначение фильтра
     */
    purpose: Array<string>;
    /**
     * Тип фильтра
     */
    type?: BoolFilter.type;
    /**
     * Количество товаров с данным значением фильтра
     */
    count?: number;
};
export namespace BoolFilter {
    /**
     * Тип фильтра
     */
    export enum type {
        BOOL = 'BOOL',
    }
}

