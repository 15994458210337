/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CharacteristicActionListReplace = {
    attributeId: number;
    action: CharacteristicActionListReplace.action;
    fromValue: string;
    toValue: string;
};
export namespace CharacteristicActionListReplace {
    export enum action {
        LIST_REPLACE = 'list_replace',
    }
}

