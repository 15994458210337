/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NumAttrOp } from './NumAttrOp';
export type NumAttrCriterion = {
    attributeId: number;
    operator: NumAttrOp;
    value: number;
    dataType: NumAttrCriterion.data_type;
};
export namespace NumAttrCriterion {
    export enum data_type {
        NUM = 'num',
    }
}

