/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EnumFilterOption } from './EnumFilterOption';
export type NumericEnumFilter = {
    /**
     * Идентификатор фильтра
     */
    id: string;
    /**
     * Название фильтра
     */
    name: string;
    /**
     * Описание фильтра (HTML)
     */
    description?: (string | null);
    /**
     * Назначение фильтра
     */
    purpose: Array<string>;
    /**
     * Тип фильтра
     */
    type?: NumericEnumFilter.type;
    /**
     * Список возможных значение фильтра
     */
    options: Array<EnumFilterOption>;
    /**
     * Тип данных
     */
    dataType: NumericEnumFilter.data_type;
    /**
     * Точность числа
     */
    precision: number;
    /**
     * Единицы измерения
     */
    unit: string;
};
export namespace NumericEnumFilter {
    /**
     * Тип фильтра
     */
    export enum type {
        ENUM = 'ENUM',
    }
    /**
     * Тип данных
     */
    export enum data_type {
        NUMERIC = 'NUMERIC',
    }
}

