/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type RangeFilter = {
    /**
     * Идентификатор фильтра
     */
    id: string;
    /**
     * Название фильтра
     */
    name: string;
    /**
     * Описание фильтра (HTML)
     */
    description?: (string | null);
    /**
     * Назначение фильтра
     */
    purpose: Array<string>;
    /**
     * Тип фильтра
     */
    type?: RangeFilter.type;
    /**
     * Точность параметра (число знаков после запятой)
     */
    precision?: number;
    /**
     * Минимальное значение параметра
     */
    minValue: number;
    /**
     * Максимальное значение параметра
     */
    maxValue: number;
    /**
     * Единица измерения
     */
    unit?: (string | null);
};
export namespace RangeFilter {
    /**
     * Тип фильтра
     */
    export enum type {
        RANGE = 'RANGE',
    }
}

