/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ModelNumericSpecification = {
    /**
     * Идентификатор характеристики
     */
    id: string;
    /**
     * Название характеристики
     */
    name: string;
    /**
     * Группа характеристик
     */
    group: string;
    /**
     * Значение
     */
    value: string;
    /**
     * Значения
     */
    values: Array<string>;
    /**
     * Назначение
     */
    purpose: Array<string>;
    /**
     * Порядок сортировки
     */
    sequence: number;
    /**
     * Описание характеристики (HTML)
     */
    description?: (string | null);
    /**
     * Всплывающая подсказа
     */
    caption?: (string | null);
    /**
     * Тип данных
     */
    dataType: ModelNumericSpecification.data_type;
    /**
     * Точность числа
     */
    precision: number;
    /**
     * Единицы измерения
     */
    unit: string;
};
export namespace ModelNumericSpecification {
    /**
     * Тип данных
     */
    export enum data_type {
        NUMERIC = 'NUMERIC',
    }
}

