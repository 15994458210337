/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ModelBoolAttrOp } from './ModelBoolAttrOp';
export type ModelBoolAttrCriterion = {
    attributeId: number;
    operator: ModelBoolAttrOp;
    value: boolean;
    dataType: ModelBoolAttrCriterion.data_type;
};
export namespace ModelBoolAttrCriterion {
    export enum data_type {
        BOOL = 'bool',
    }
}

