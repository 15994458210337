/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ModelNumAttrOp } from './ModelNumAttrOp';
export type ModelNumAttrCriterion = {
    attributeId: number;
    operator: ModelNumAttrOp;
    value: number;
    dataType: ModelNumAttrCriterion.data_type;
};
export namespace ModelNumAttrCriterion {
    export enum data_type {
        NUM = 'num',
    }
}

